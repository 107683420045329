import React, { useEffect, useState } from 'react';

import Image from '../Image';
import Link from '../link/Link';
import Button from '../forms/Button';
import Holder from '../layout/Holder';
import { getRoot } from '../../../utils/utils';
import constants, { SCREEN_SIZES } from '../../../utils/constants';
import { Book } from '../../../graphqlTypes';
import useWindowSize from '../../../hooks/useWindowSize';
import PriceProductListed from './PriceProductListed';
import LoadingOval from '../LoadingOval';

interface ProductListedProps {
  product?: Book;
  category?: string;
  onClick?: (value, prop?) => void;
  search?: (value, prop?, click?) => void;
  price?: number;
  prevPrice?: number;
  noPrev?: boolean;
  ean?: number | string;
  bill?: boolean;
  titleFriendly?: string;
  canceledDate?: string;
  itemState?: string;
  image?: string;
  title?: string;
  subTitle?: string;
  magazine?: boolean;
  toggleSearch?: () => void;
  className?: string;
  slider?: boolean;
  results?: boolean;
  isLoading?: boolean;
  onClickToSearch?: (product: Book) => void;
}

interface ProductListedState {
  product?: Book;
}

const ProductListed = ({
  product,
  price,
  prevPrice,
  noPrev,
  ean,
  bill,
  titleFriendly,
  canceledDate,
  itemState,
  image,
  title,
  subTitle,
  magazine,
  className,
  slider,
  results,
  category: categoryProps,
  isLoading = true,
  onClickToSearch,
}: ProductListedProps): JSX.Element => {
  const [state, setState] = useState<ProductListedState>({ product });
  const category = state.product?.product_type || categoryProps || 'magazine';
  const { documentWidth } = useWindowSize();

  useEffect(() => {
    setState((prevState) => ({ ...prevState, product }));
  }, [product]);

  const onGoToSearch = (): void => {
    if (onClickToSearch) onClickToSearch(product);
  };

  const getAuthorsString = (): string => {
    if (state.product?.tags?.length) {
      const authorsTag = state.product.tags.find((tag) => tag.category === 'authors');

      if (authorsTag) {
        return authorsTag.items.join(' — ');
      }

      if (state.product?.book?.authors) {
        return state.product?.book?.authors?.map((q) => q.name).join(' — ');
      }
    }
    return null;
  };

  const finalId = (): string | number => {
    if (bill) {
      return ean;
    }

    if (category === constants.allProductTypes[1] || category === constants.allProductTypes[2]) {
      return state.product?.id || ean;
    }

    return state.product?.ean || ean;
  };

  const getHref = (): string => {
    const root = getRoot(category);
    if (bill) {
      const id = finalId();
      const titleHref = titleFriendly || 'untitled';
      return `${root + id}/${titleHref}`;
    }

    if (state.product) {
      const id = category === 'magazine' || magazine ? state.product?._id : finalId();
      const titleHref = titleFriendly || 'untitled';
      return !magazine ? `${root + id}/${titleHref}` : `/ficha/revista/${id}/${titleHref}`;
    }
    return undefined;
  };

  const fechaCancel = (): string => {
    if (itemState === 'cancelled' && canceledDate) {
      return new Date(canceledDate).toLocaleDateString();
    }
    return undefined;
  };

  if (bill) {
    return (
      <Link href={getHref()}>
        <div className="productListed">
          <Holder>
            <Image src={image || '/img/no-image.jpg'} width="100" height="50" />

            <Holder center column>
              {fechaCancel() && (
                <span className="subTitle">{`Producto cancelado el ${fechaCancel()}`}</span>
              )}
              <p className="title">{title || ''}</p>
              {documentWidth > SCREEN_SIZES.MAX_MOBILE ? (
                <p className="subTitle">{subTitle || ''}</p>
              ) : null}
            </Holder>
          </Holder>

          <PriceProductListed
            product={state.product}
            bill
            noPrev={noPrev}
            priceProp={price}
            prevPrice={prevPrice}
          />
        </div>
      </Link>
    );
  }

  if (state.product) {
    return (
      <Link
        href={getHref()}
        className={`item productPreview previewHolder ${className || ''}${slider ? ' slider' : ''}${
          results ? ' result' : ''
        }`}
      >
        <Button onClick={onGoToSearch} noStyle className="productListed">
          <Holder>
            <Image
              alt={
                state.product?.product_type === constants.allProductTypes[0]
                  ? `Portada del libro ${state.product.ean} ${state.product.title}`
                  : state.product?.title
              }
              src={state.product?.mainImg ? state.product.mainImg : '/img/no-image.jpg'}
              width="100"
              height="50"
            />
            {isLoading && (
              <div style={{ position: 'absolute' }}>
                <LoadingOval
                  ariaLabel="loading-indicator-product-listed"
                  height={45}
                  width={45}
                  strokeWidth={3}
                  color="red"
                  secondaryColor="white"
                />
              </div>
            )}
            <Holder center column>
              <p className="title">{title || state.product?.title}</p>
              <p className="subTitle">{getAuthorsString()}</p>
              <p className="subTitle">
                {(category === 'book' || category === 'ebook') && state.product?.book?.edition
                  ? state.product.book.edition.year
                  : null}
              </p>
            </Holder>
          </Holder>

          {documentWidth > SCREEN_SIZES.MAX_MOBILE && state.product.prices ? (
            <PriceProductListed product={state.product} />
          ) : null}
        </Button>
      </Link>
    );
  }

  return null;
};

export default ProductListed;
