import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import getPlaceHolderSearch from './utils/searchBox.utils';
import useWindowSize from '../../../hooks/useWindowSize';
import Field from '../forms/Field/Field';
import { getDataFromStorage, NameStorage } from '../../../utils/services/localStorage.service';

interface SearchBoxProps {
  abc?: boolean;
  changeFixedFilter: (value: number) => void;
  onEnterKey: (value: string, data?: boolean) => void;
  onArrowKey: (num: number) => void;
  search: (searchValue: string) => void;
  searchScrollTop?: boolean;
  fullScreen?: boolean;
  hidden?: boolean;
  value?: string;
  desktop?: boolean;
  toggleSearch?: () => void;
  areas: boolean;
  defaultValue?: string;
}

const SearchBox = ({
  abc,
  changeFixedFilter,
  searchScrollTop,
  fullScreen,
  hidden,
  desktop,
  toggleSearch,
  areas,
  onEnterKey,
  onArrowKey,
  search: searchFn,
  defaultValue,
  ...props
}: SearchBoxProps): JSX.Element => {
  const { asPath } = useRouter();
  const { height } = useWindowSize();
  const [state, setState] = useState({
    abcActive: abc,
    newPlaceholder: 'Buscar en todo',
  });

  const getPlaceholder = (): void => {
    let type = '';
    if (process.browser && asPath) {
      type = asPath;
    }

    if (areas) {
      setState((prevState) => ({
        ...prevState,
        newPlaceholder: 'Buscar área de interés',
      }));
      return;
    }

    const areaOfInterestFromStorage = getDataFromStorage<number>(NameStorage.areaOfInterest);
    const areaOfInterestNameFromStorage = getDataFromStorage<string>(
      NameStorage.areaOfInterestName,
    );

    const newPlaceholder = getPlaceHolderSearch(
      type,
      areaOfInterestFromStorage,
      areaOfInterestNameFromStorage,
    );

    setState((prevState) => ({
      ...prevState,
      newPlaceholder,
    }));
  };

  useEffect(() => {
    getPlaceholder();
  }, [areas]);

  const onKeyUp = (e, valueData): void => {
    if (!e) {
      return;
    }

    const keyCode = e.keyCode || e.which;

    if (keyCode === 13 || keyCode === 40 || keyCode === 38) {
      e.preventDefault();
    }

    switch (keyCode) {
      case 13: {
        // Enter
        if (onEnterKey) {
          onEnterKey(valueData);
        }
        return;
      }
      case 38:
        onArrowKey(38);
        return;
      case 40:
        onArrowKey(40);
        return;
      default:
        searchFn(valueData);
        break;
    }
  };

  const onEmptyValue = (): void => {
    onEnterKey('', true);
  };

  useEffect(() => {
    getPlaceholder();
    if (changeFixedFilter) {
      changeFixedFilter(height);
    }
  }, []);

  const search = (e): void => {
    onKeyUp(e, e.target.value);
  };

  return (
    <div
      className={`searchBox${searchScrollTop ? ' scrolled' : ''}${fullScreen ? ' fullscreen' : ''}${
        abc ? ' abc' : ''
      }${hidden ? ' hidden' : ''}`}
    >
      <Field
        {...props}
        id="searchBox"
        type="search"
        desktop={desktop}
        onEmptyValue={onEmptyValue}
        defaultValue={defaultValue}
        propsInput={{
          onFocus: search,
          placeholder: state.newPlaceholder,
          onKeyUp: search,
        }}
        toggleSearch={toggleSearch}
      />
    </div>
  );
};

export default SearchBox;
