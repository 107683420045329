import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import useGetType from '../../../hooks/useGetType';
import {
  compareValues,
  indexArrowKey,
  urlSearchTagsByArea,
  valueToSendOnEnterKey,
} from './utils/search.util';
import useDebounce from '../../../hooks/useDebounce';
import {
  getPreditionAreas,
  getResultPredictive,
  getResultPredictiveSearch,
  getTagPrediction,
} from './search.service';
import SearchBox from './SearchBox';
import constants from '../../../utils/constants';
import { TagsResult, Area, Book } from '../../../graphqlTypes';
import PredictiveSectionSearch from './PredictiveSectionSearch';
import PredictionTag from './PredictionTag';
import PredictionProduct from './PredictionProduct';
import PredictionArea from './PredictionArea';
import {
  getDataFromStorage,
  NameStorage,
  removeDataInStorage,
} from '../../../utils/services/localStorage.service';
import useSearch from '../../../hooks/useSearch';

const { sectionWithAreasOfInterest } = constants;

const typeDataAccepts = ['book', 'model', 'material', 'ebook', 'poster'];

interface SearchState {
  isSearching: boolean;
  search: string;
  fadeIn: boolean;
  prevSearch: string;
  predictions: Book[];
  predictionsTags: TagsResult[];
  prevIndexArrow: number;
  indexArrow: number;
  resultsPredictive?: TagsResult[];
  isSearchingPredictive?: boolean;
  isSearchingResults?: boolean;
  predictionsAreas?: Area[];
}

interface SearchProps {
  changeSearching: (data: boolean) => void;
  search?: string;
  tamagno?: boolean;
  buscando?: boolean;
  desktop?: boolean;
  changeFixedFilter?: (pixels: number) => void;
  fullScreen?: boolean;
  areaOfInterestName?: string;
  toggleSearch?: () => void;
  setFullScreen?: (data: boolean, data2?: string) => void;
  actualizaArea?: (data) => void;
  disabled?: boolean;
  searchScrollTop?: boolean;
  keys?: unknown;
  results?: boolean;
}

const Search = ({
  changeSearching,
  search: searchProps,
  tamagno,
  buscando: buscandoProps,
  desktop,
  changeFixedFilter,
  fullScreen,
  areaOfInterestName,
  toggleSearch,
  setFullScreen,
  actualizaArea,
  ...props
}: SearchProps): JSX.Element => {
  const { search: searchField, setSearch: setSearchField, isTheLastPagesSaved } = useSearch();
  const valuesToSearch = useRef<number>(null);
  const { pathname, push } = useRouter();
  const [buscando, setBuscando] = useState<boolean>(
    tamagno && pathname?.indexOf('/areas') === 0 ? buscandoProps : true,
  );
  const [loading, setLoading] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);
  const [contador, setContador] = useState(0);
  const [state, setState] = useState<SearchState>({
    isSearching: false,
    search: searchProps,
    fadeIn: false,
    prevSearch: null,
    predictions: [],
    predictionsTags: [],
    prevIndexArrow: -1,
    indexArrow: -1,
  });

  const { debouncedValue } = useDebounce(state.search);
  const { route: type } = useGetType();

  const searchBox = useRef(null);
  const predictiveWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setBuscando(buscandoProps);
  }, [buscandoProps]);

  useEffect(() => {
    changeSearching(true);
  }, []);

  const searchTagsByArea = (value: string): void => {
    setState((prevState) => ({
      ...prevState,
      isSearching: false,
      fadeIn: false,
    }));

    setFullScreen(false, '');
    const url = urlSearchTagsByArea(value, pathname, type);

    push(url);
  };

  const onArrowKey = (key: number): void => {
    const indexArrow = indexArrowKey(state.indexArrow, key, state.resultsPredictive?.length);

    setState((prevState) => ({ ...prevState, indexArrow }));
  };

  const resultPrediction = async (
    value: string,
    typeData: string,
    areas: number | number[],
  ): Promise<void> => {
    const { ok, data: predictions } = await getResultPredictive({
      search: value,
      limit: 20,
      areas_of_interest: sectionWithAreasOfInterest.includes(typeData) ? areas : null,
      orderByScore: true,
      product_type: typeData ? [typeData] : constants.allProductTypes,
    });

    if (!ok) return;

    setLoading(false);
    setState((prevState) => ({
      ...prevState,
      predictions,
      isSearchingResults: false,
    }));
  };

  const tagPrediction = async (
    value: string,
    typeData: string,
    areas: number | number[],
  ): Promise<void> => {
    const { ok, data: predictionsTags } = await getTagPrediction({
      search: value,
      limit: 10,
      areas_of_interest: sectionWithAreasOfInterest.includes(typeData) ? areas : null,
      product_type: typeData ? [typeData] : constants.allProductTypes,
    });

    if (!ok) return;

    setLoadingTags(false);
    setState((prevState) => ({ ...prevState, predictionsTags }));
  };

  const onGetResultPredictiveSearch = (resultsPredictive: TagsResult[]): void => {
    if (!resultsPredictive) return;

    const areaOfInterestFromStorage = getDataFromStorage<number>(NameStorage.areaOfInterest);
    const areaOfInterestNameFromStorage = getDataFromStorage<string>(
      NameStorage.areaOfInterestName,
    );

    const areas = areaOfInterestNameFromStorage && areaOfInterestFromStorage;

    const results = resultsPredictive.filter((r) => r.name !== 'AGOTADO');
    setState((prevState) => ({
      ...prevState,
      resultsPredictive: results,
      isSearchingPredictive: false,
      product_type: type ? [type] : constants.allProductTypes,
    }));

    let finalValue = { hover: null, value: state.search };

    if (results?.length) {
      finalValue = compareValues(results[0].name, state.search);
      const indexArrow = finalValue.hover ? 0 : -1;
      const prevIndexArrow = finalValue.hover ? 0 : -1;

      setState((prevState) => ({
        ...prevState,
        indexArrow,
        prevIndexArrow,
      }));
    }

    resultPrediction(finalValue?.value, type, areas);
    tagPrediction(finalValue?.value, type, areas);
  };

  const searchingPrediction = async (values: string, typeData: string): Promise<void> => {
    const currentContador = contador + 1;
    const value = values ? values.replace('.', '').replace(',', '') : '';
    setContador(currentContador);

    const areaOfInterestFromStorage = getDataFromStorage<number>(NameStorage.areaOfInterest);
    const areaOfInterestNameFromStorage = getDataFromStorage<string>(
      NameStorage.areaOfInterestName,
    );

    const areas = areaOfInterestNameFromStorage && areaOfInterestFromStorage;

    setLoading(true);
    setLoadingTags(true);

    if (!typeData || typeDataAccepts.includes(typeData)) {
      const { ok, data } = await getResultPredictiveSearch({
        search: value,
        limit: 10,
        areas_of_interest: sectionWithAreasOfInterest.includes(typeData) ? areas : null,
        product_type: typeData ? [typeData] : constants.allProductTypes,
      });

      if (!ok) return;

      onGetResultPredictiveSearch(data);

      return;
    }

    if (typeData === 'areas') {
      const { ok, data: predictionsAreas } = await getPreditionAreas({
        search: value,
        limit: 0,
      });

      setLoading(false);
      if (!ok) return;

      setState((prevState) => ({ ...prevState, predictionsAreas }));
    }
  };

  const _search = (value: string, forceAll = false): void => {
    if (value?.length > 2) {
      if ((value !== state.prevSearch || forceAll) && type !== 'tags') {
        valuesToSearch.current = 1;
        setState((prevState) => ({
          ...prevState,
          prevSearch: value,
          search: value,
          fadeIn: true,
          isSearching: true,
          isSearchingResults: true,
          isSearchingPredictive: true,
        }));
        setFullScreen(true, value);
        return;
      }
      if (type === 'tags') {
        valuesToSearch.current = 2;
      }
      return;
    }

    if (value?.length === 0 && type === 'tags') {
      valuesToSearch.current = 3;
    }

    setState((prevState) => ({
      ...prevState,
      isSearching: false,
      isSearchingPredictive: false,
      fadeIn: false,
      prevSearch: null,
    }));

    changeSearching(false);
  };

  const executeSearchPetition = (): void => {
    if (debouncedValue) setSearchField(debouncedValue);

    if (valuesToSearch.current === 1) {
      searchingPrediction(debouncedValue, type);
      return;
    }

    if (valuesToSearch.current === 2) {
      searchTagsByArea(debouncedValue);
      return;
    }

    if (valuesToSearch.current === 3) {
      searchTagsByArea('');
    }
  };

  useEffect(() => {
    executeSearchPetition();
  }, [debouncedValue]);

  const searchFn = (value: string, all = false): void => {
    _search(value, all);
  };

  const setSearchWord = (value: string, expanded?: boolean): void => {
    if (expanded || !value) {
      searchFn(value);
    }
  };

  const changeCurrentSearchBox = (valueToSend): void => {
    searchBox.current = valueToSend;
  };

  const onEnterKey = (value: string, close: boolean): void => {
    const [fisrtResultsPredictive] = state.resultsPredictive || [];
    const { name: nameFisrtResultsPredictive } = fisrtResultsPredictive || {};

    if (
      state.indexArrow !== state.prevIndexArrow &&
      value &&
      nameFisrtResultsPredictive !== value
    ) {
      setFullScreen(true, value);
      setSearchWord(state.resultsPredictive?.[state.indexArrow]?.name || '', true);

      return;
    }

    const valueToSend = valueToSendOnEnterKey(
      state.indexArrow,
      state.resultsPredictive?.length,
      value,
      nameFisrtResultsPredictive,
      type,
      close,
      changeCurrentSearchBox,
    );

    changeCurrentSearchBox(valueToSend);

    if (!type || typeDataAccepts.includes(type)) {
      setFullScreen(true, '');
      setState((prevState) => ({
        ...prevState,
        isSearching: false,
        fadeIn: false,
      }));

      if (!close) {
        setFullScreen(false, valueToSend || value);

        push(`/resultados/${valueToSend.toLowerCase() || value.toLowerCase()}`);
        return;
      }

      if (!value) {
        setSearchWord('');
      }
    }

    if (type === 'tags') {
      setFullScreen(false, value);
      searchTagsByArea(value);
    }
  };

  const searchAll = (): void => {
    removeDataInStorage(NameStorage.areaOfInterestName);
    removeDataInStorage(NameStorage.areaOfInterest);

    executeSearchPetition();
    if (actualizaArea) actualizaArea(null);
  };

  const goToTags = (url: string): void => {
    setFullScreen(true, '');
    setState((prevState) => ({
      ...prevState,
      isSearching: false,
      fadeIn: false,
    }));

    setFullScreen(false, '');
    push(url);
  };

  const topResults = (): number => {
    const [clientRectPredictiveWrapper] = predictiveWrapper?.current?.getClientRects() || [];
    let top = 120;
    if (clientRectPredictiveWrapper) {
      top += clientRectPredictiveWrapper.height;
    }
    return top;
  };

  useEffect(() => {
    setState((prevState) => ({ ...prevState, search: searchProps }));
    searchFn(state.search);
  }, [searchProps]);

  const onToggleSearch = (): void => {
    if (toggleSearch) {
      toggleSearch();
    }
  };

  return (
    <div className="search">
      <SearchBox
        {...props}
        defaultValue={isTheLastPagesSaved() && searchField}
        desktop={desktop}
        onEnterKey={onEnterKey}
        onArrowKey={onArrowKey}
        search={searchFn}
        changeFixedFilter={changeFixedFilter}
        toggleSearch={onToggleSearch}
        abc={type === 'tags'}
        areas={type === 'areas'}
      />

      {state.resultsPredictive?.length && state.isSearching ? (
        <PredictiveSectionSearch
          fullScreen={fullScreen}
          indexArrow={state.indexArrow}
          isSearchingPredictive={state.isSearchingPredictive}
          resultsPredictive={state.resultsPredictive}
          setSearchWord={setSearchWord}
          ref={predictiveWrapper}
        />
      ) : null}

      {state.isSearching && buscando && (
        <div
          className={`resultsWrapper ${state.fadeIn ? 'fadeIn' : ''}`}
          style={{ top: `${topResults()}px` }}
        >
          <div className="results">
            {(!type || typeDataAccepts.includes(type)) && (
              <>
                <div className="tags">
                  <PredictionTag
                    areaOfInterestName={areaOfInterestName}
                    goToTags={goToTags}
                    loadingTags={loadingTags}
                    predictionsTags={state.predictionsTags}
                    type={type}
                  />
                </div>

                <div className="predictiveSearch">
                  <PredictionProduct
                    _search={_search}
                    areaOfInterestName={areaOfInterestName}
                    isSearchingResults={state.isSearchingResults}
                    loading={loading}
                    predictionProducts={state.predictions}
                    searchAll={searchAll}
                    toggleSearch={toggleSearch}
                  />
                </div>
              </>
            )}

            {type === 'areas' && (
              <div className="predictiveSearch pageContent listOfAreas">
                <ul className="areaGroup">
                  <PredictionArea predictionsAreas={state.predictionsAreas} />
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;
