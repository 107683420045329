import React, { useEffect, useState } from 'react';
import speakingUrl from 'speakingurl';
import { useRouter } from 'next/router';
import Link from './link/Link';
import Button from './forms/Button';
import { Area as IArea } from '../../graphqlTypes';
import {
  getDataFromStorage,
  isClient,
  NameStorage,
} from '../../utils/services/localStorage.service';
import { Filter } from './Filters/model/filters.model';
import constants from '../../utils/constants';

const { sectionSpanishWithAreasOfInterest } = constants;

interface AreaProps {
  filter?: Filter;
  areaName?: string;
  iconClass?: boolean;
  iconLeft?: boolean;
  changeFilterArea?: (data) => void;
  areaPage?: boolean;
  area?: IArea;
  areas?: IArea[];
  nResults?: {
    areasOfInterest: { name: string; total: number }[];
  };
  setArea?: (areaName: string) => void;
}

const Area = ({
  filter,
  areaName,
  iconClass,
  iconLeft,
  changeFilterArea,
  areaPage,
  area,
  areas,
  nResults,
  setArea,
  ...props
}: AreaProps): JSX.Element => {
  const { push } = useRouter();

  const [lastRoute, setLastRoute] = useState<string>(null);

  useEffect(() => {
    const route = getDataFromStorage<string>(NameStorage.myurl);
    setLastRoute(sectionSpanishWithAreasOfInterest.includes(route) ? route : 'libros');
  }, []);

  const buildAreasOfInteres = (): { total: number; active: boolean } => {
    const totalInterest = nResults.areasOfInterest;
    const filters = filter ? JSON.parse(JSON.stringify(filter)) : null;
    const filterAreas = filters['Áreas'];
    let active = false;
    let total = 0;
    const areaFound = areas.find((x) => x.nameEs.toLowerCase() === areaName.toLowerCase());
    if (areaFound) {
      const areaFind = totalInterest
        ? totalInterest.find((e) => e.name === areaFound.id.toString())
        : null;
      total = areaFind ? areaFind.total : 0;
      active = filterAreas ? filterAreas.indexOf(area.id.toString()) !== -1 : false;
    }

    return { total, active };
  };

  const actualizaAreaOfInterest = (): void => {
    if (isClient() && areaName) {
      if (setArea) setArea(areaName);
    }
  };

  if (filter) {
    const filterArea = buildAreasOfInteres();
    return (
      <li className="area">
        <Button
          iconClass={filterArea.active && iconClass && 'check'}
          iconLeft={filterArea.active && iconLeft}
          className={filterArea.active ? 'active' : ''}
          onClick={changeFilterArea}
          type="submit"
          value={areaName}
          noStyle
        >
          <>
            {areaName}
            {`${(<span>{filterArea.total}</span>)}`}
          </>
        </Button>
      </li>
    );
  }

  if (areaPage) {
    return (
      <li className="area">
        <Button
          className="link"
          onClick={(): void => {
            actualizaAreaOfInterest();
            const slug = speakingUrl(areaName.replace(/, /g, '.'), { custom: ['.'] });
            push(`/${lastRoute}/${slug}`);
          }}
          type="submit"
          value={areaName}
          noStyle
        >
          {areaName}
        </Button>
      </li>
    );
  }

  return (
    <li className="area">
      <Link {...props}>{areaName}</Link>
    </li>
  );
};

export default Area;
