const getPlaceHolderSearch = (
  type: string,
  areaOfInterestFromStorage: number,
  areaOfInterestNameFromStorage: string,
): string => {
  switch (true) {
    case type.indexOf('/libros') > -1: {
      if (areaOfInterestFromStorage && areaOfInterestNameFromStorage) {
        return `Buscar en ${areaOfInterestNameFromStorage}`;
      }

      if (type.indexOf('/tags') === 0) {
        return 'Buscar en Temas';
      }

      return 'Buscar en Libros';
    }

    case type.indexOf('/modelos-anatomicos-educativos') > -1: {
      if (type.indexOf('/tags') === 0) {
        return 'Buscar en Temas';
      }

      return 'Buscar en Modelos';
    }

    case type.indexOf('/material-y-suministros-medicos') > -1: {
      if (type.indexOf('/tags') === 0) {
        return 'Buscar en Temas';
      }

      return 'Buscar en Material';
    }

    case type.indexOf('/poster') > -1: {
      if (type.indexOf('/tags') === 0) {
        return 'Buscar en Temas';
      }

      return 'Buscar en Láminas';
    }

    case type.indexOf('/tags') > -1: {
      return 'Buscar en Tags';
    }

    case type.indexOf('/ebooks') > -1: {
      return 'Buscar en eBooks';
    }

    default: {
      return 'Buscar en todo';
    }
  }
};

export default getPlaceHolderSearch;
