import React from 'react';

import { Book } from '../../../graphqlTypes';
import { getTitleProduct } from './priceProductListed.util';

const customFormatNumber = (num: number): string =>
  Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(num);

interface PriceProductListed {
  product: Book;
  bill?: boolean;
  priceProp?: number;
  noPrev?: boolean;
  prevPrice?: number;
}

const PriceProductListed: React.FC<PriceProductListed> = ({
  product,
  bill,
  priceProp,
  noPrev,
  prevPrice,
}) => {
  const { prices, relatedInfo, product_type, priceWithDiscount } = product || {};
  const { sale: salePrice } = prices || {};
  const descriptionTitle = getTitleProduct(product_type);

  if (bill) {
    if (priceProp) {
      return (
        <p className="price">
          {!noPrev && <em>{`${prevPrice || null} €`}</em>}
          {priceProp ? customFormatNumber(priceProp) : null}
        </p>
      );
    }
    return null;
  }

  return (
    <div className="pricesEbook">
      <div className="priceTypeWrapper">
        {descriptionTitle ? <p className="priceType">{descriptionTitle}</p> : null}
        <p className="price">
          {prices && priceWithDiscount
            ? customFormatNumber(priceWithDiscount)
            : customFormatNumber(salePrice)}
        </p>
      </div>

      {relatedInfo ? (
        <div className="priceTypeWrapper">
          <p className="priceType">
            {relatedInfo.product_type === 'book' ? 'Libro' : relatedInfo.product_type}
          </p>

          <p className="price">
            {relatedInfo.priceWithDiscount
              ? customFormatNumber(relatedInfo.priceWithDiscount)
              : customFormatNumber(salePrice)}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default PriceProductListed;
