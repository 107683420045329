import ProductTypes from '../../../../models/common.model';

const urlPredictionTag = (
  predictionName: string,
  predictionCategory: string,
  type: string,
): string => {
  let value = predictionName;
  let valueUrl = value.replace(new RegExp('/', 'g'), '%2F');
  const typeRoute = ProductTypes[type];

  if (type) {
    value = `${typeRoute}/${predictionName}`;
    valueUrl = `${typeRoute}/${valueUrl}`;
  }

  return `/resultados/${predictionCategory}/${valueUrl}`;
};

export default urlPredictionTag;
