import React from 'react';

import { Area } from '../../../graphqlTypes';
import AreaComponent from '../Area';

interface PredictionAreaProps {
  predictionsAreas?: Area[];
}

const PredictionArea: React.FC<PredictionAreaProps> = ({ predictionsAreas }) => {
  return (
    <>
      {predictionsAreas?.map((predictionArea) => (
        <AreaComponent
          area={predictionArea}
          key={`a_o_i_${predictionArea._id}`}
          areas={predictionsAreas}
          areaPage
          areaName={predictionArea.nameEs}
        />
      ))}

      {!predictionsAreas?.length ? (
        <p key="predictions_noResults_area" className="noResults">
          No hay resultados con esa búsqueda
        </p>
      ) : null}
    </>
  );
};

export default PredictionArea;
