import React, { forwardRef } from 'react';

// import Loader from '../layout/Loader';
import { TagsResult } from '../../../graphqlTypes';
import Button from '../forms/Button';
// import LoadingOval from "../LoadingOval";

interface PredictiveSectionSearchProps {
  fullScreen: boolean;
  isSearchingPredictive: boolean;
  resultsPredictive: TagsResult[];
  indexArrow: number;
  setSearchWord: (value: string, expanded?: boolean) => void;
}

const PredictiveSectionSearch = forwardRef(
  (
    {
      fullScreen,
      isSearchingPredictive,
      resultsPredictive,
      indexArrow,
      setSearchWord,
    }: PredictiveSectionSearchProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    return (
      <div ref={ref} className={`predictiveWrapper ${fullScreen ? ' fullscreen' : ''}`}>
        {!isSearchingPredictive ? (
          <div className="predictive">
            {resultsPredictive?.map((predictive, i) => (
              <Button
                key={`predictionTerms${predictive._id}`}
                onClick={(): void => setSearchWord(`${predictive.name}`, true)}
                noStyle
                className={`predictiveItem ${i === indexArrow ? 'selected' : ''}`}
              >
                {predictive.name}
              </Button>
            ))}
          </div>
        ) : (
          null
          // <LoadingOval
          //   ariaLabel="loading-indicator-ProductListining"
          //   height={25}
          //   width={28}
          //   strokeWidth={3.5}
          //   color="red"
          //   secondaryColor="white"
          // />
        )}
      </div>
    );
  },
);

export default PredictiveSectionSearch;
