import React, { CSSProperties, useState } from 'react';

import useFiltersAndOrder from '../../hooks/useFiltersAndOrder';
import Link from './link/Link';
import Button from './forms/Button';
import { ResultsAggregate, Tags } from '../../graphqlTypes';

const classNamePropsDic = {
  eBook: 'eBook',
  yes: 'stock',
  no: 'noStock',
  'no stock': 'noStock',
  soon: 'soon',
};

const availableDic = {
  eBook: 'eBook',
  yes: 'En stock',
  no: 'Agotado',
  soon: 'Proximamente',
  'no stock': 'Sin stock',
};

interface TagProps {
  active?: boolean;
  onClick?: (event?: Record<string, unknown>, categoryValue?: ResultsAggregate[]) => void;
  button?: boolean;
  // Esta category no es la misma category que se usa normalmente
  category?: ResultsAggregate[];
  buttonFinish?: boolean;
  name?: string;
  valueTag?: string;
  className?: string;
  style?: CSSProperties;
  value?: string;
  items?: string;
  avoidBlink?: boolean;
  preventDefault?: boolean;
  url?: string;
  available?: string;
  filter?: boolean;
  color?: string;
  product?: Tags;
}

const Tag = ({
  onClick,
  button,
  category,
  buttonFinish,
  name,
  valueTag,
  value,
  style,
  items,
  avoidBlink,
  preventDefault,
  url,
  available,
  filter,
  color,
  className: classes,
}: TagProps): JSX.Element => {
  const { filter: filterValue } = useFiltersAndOrder();
  const validateActiveTag = (): boolean => {
    if (!filterValue?.Tags?.length) return false;
    return filterValue.Tags?.some((tag) => tag.prop === name && tag.items.includes(valueTag));
  };

  const [state, setState] = useState({
    active: validateActiveTag(),
  });

  const swichActive = (): void => {
    if (onClick) {
      if (button) {
        onClick({ prop: name, value: valueTag }, category);
      } else {
        onClick();
      }
    }

    setState((prevState) => ({ active: !prevState.active }));
  };

  if (buttonFinish) {
    return (
      <Button onClick={swichActive} className={`tag filter ${classes}`} style={style}>
        {value}
        {items && <span>{items}</span>}
      </Button>
    );
  }

  if (!button) {
    return (
      <Link
        avoidBlink={avoidBlink}
        preventDefault={preventDefault}
        href={url}
        rel="nofollow"
        onClick={swichActive}
        className={`tag  ${classes} ${classNamePropsDic[available]}${filter ? ' filter' : ''} ${
          color || ''
        }`}
      >
        {Object.keys(availableDic).includes(available) ? (
          <span>{availableDic[available]}</span>
        ) : (
          <>{value}</>
        )}

        {items && <span>{items}</span>}
      </Link>
    );
  }

  return (
    <Button
      onClick={swichActive}
      className={`tag filter ${state.active ? 'active' : ''} ${classes || ''} ${color || ''}${
        value === 'Próximas publicaciones' ? ' nextPublications' : ''
      }`}
      style={style}
    >
      {value}

      {items && <span>{items}</span>}
    </Button>
  );
};

export default Tag;
