import ProductTypes from '../../../../models/common.model';

const typeDataAccepts = ['book', 'model', 'material', 'ebook', 'poster'];

export const urlSearchTagsByArea = (value: string, route: string, type: string): string => {
  let finalValue = value;
  let typeRoute = null;

  if (route) {
    [, typeRoute] = route.split('/');
  }

  if (
    type &&
    typeRoute &&
    (typeRoute.indexOf('libros') > -1 ||
      typeRoute.indexOf('ebooks') > -1 ||
      typeRoute.indexOf('modelos-anatomicos-educativos') > -1 ||
      typeRoute.indexOf('material-y-suministros-medicos') > -1 ||
      typeRoute.indexOf('poster') > -1)
  ) {
    finalValue = `${typeRoute}${
      finalValue ? `/${finalValue}` : `${typeRoute && route !== '/tags' ? '/%20' : ''}`
    }`;
  }

  return `/tags${finalValue ? `/${finalValue}` : ''}`;
};

export const indexArrowKey = (
  currentIndex: number,
  key: number,
  resultsPredictiveSize: number,
): number => {
  if (key === 38 && currentIndex >= 0) {
    return currentIndex - 1;
  }

  if (key === 40 && currentIndex + 1 < resultsPredictiveSize) {
    return currentIndex + 1;
  }

  return currentIndex;
};

export const compareValues = (
  predictiveValue: string,
  value: string,
): { value: string; hover: boolean } => {
  if (predictiveValue?.trim()?.split(' ')?.length === value?.trim()?.split(' ')?.length) {
    return {
      value: predictiveValue,
      hover: true,
    };
  }
  const predictive = predictiveValue.split(' ');
  const subArray = predictive.slice(0, value?.split(' ')?.length);

  let finalWord = subArray.join(' ');

  // delete last comma or dot
  const charsToDelete = [',', '.'];
  if (charsToDelete.indexOf(finalWord[finalWord.length - 1]) !== -1) {
    finalWord = finalWord.substring(0, finalWord.length - 1);
  }

  return {
    value: finalWord,
    hover: false,
  };
};

export const valueToSendOnEnterKey = (
  indexArrow: number,
  resultsPredictiveSize: number,
  value: string,
  nameFisrtResultsPredictive: string,
  type: string,
  close: boolean,
  changeCurrentSearchBox: (valueToSend) => void,
): string => {
  let valueToSend = '';

  if (indexArrow === -1) {
    valueToSend = resultsPredictiveSize
      ? compareValues(nameFisrtResultsPredictive, value).value
      : value;
  } else {
    valueToSend = resultsPredictiveSize ? nameFisrtResultsPredictive : null;
  }

  changeCurrentSearchBox(valueToSend);

  if (!type || typeDataAccepts.includes(type)) {
    const typeRoute = ProductTypes[type] || '';

    if (type) {
      valueToSend = `${typeRoute}/${valueToSend || value}`;
    }

    if (!close) {
      return valueToSend?.toLowerCase() || value?.toLowerCase();
    }
  }

  return valueToSend;
};
