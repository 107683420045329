/* eslint-disable import/prefer-default-export */

import {
  Area,
  Book,
  GetAreasPredictiveDocument,
  GetAreasPredictiveQuery,
  GetAreasPredictiveQueryVariables,
  GetResultsPredictiveDocument,
  GetResultsPredictiveQuery,
  GetResultsPredictiveQueryVariables,
  GetResultsPredictiveSearchDocument,
  GetResultsPredictiveSearchQuery,
  GetResultsPredictiveSearchQueryVariables,
  GetTagsPredictiveDocument,
  GetTagsPredictiveQuery,
  GetTagsPredictiveQueryVariables,
  TagsResult,
} from '../../../graphqlTypes';
import { initializeApollo } from '../../../graphql/apollo';
import { Response } from '../../../models/response.model';

export const getPreditionAreas = async (
  variables: GetAreasPredictiveQueryVariables,
): Promise<Response<Area[]>> => {
  try {
    const { query } = initializeApollo();

    const {
      data: { getAreasPredictive },
    } = await query<GetAreasPredictiveQuery>({
      query: GetAreasPredictiveDocument,
      variables,
      fetchPolicy: 'cache-first',
    });

    return {
      ok: !!getAreasPredictive,
      data: getAreasPredictive,
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};

export const getResultPredictiveSearch = async (
  variables: GetResultsPredictiveSearchQueryVariables,
): Promise<Response<TagsResult[]>> => {
  try {
    const { query } = initializeApollo();

    const {
      data: { resultsPredictive },
    } = await query<GetResultsPredictiveSearchQuery>({
      query: GetResultsPredictiveSearchDocument,
      variables,
      fetchPolicy: 'cache-first',
    });

    return {
      ok: !!resultsPredictive,
      data: resultsPredictive,
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};

export const getResultPredictive = async (
  variables: GetResultsPredictiveQueryVariables,
): Promise<Response<Book[]>> => {
  try {
    const { query } = initializeApollo();

    const {
      data: { books },
    } = await query<GetResultsPredictiveQuery>({
      query: GetResultsPredictiveDocument,
      variables,
      fetchPolicy: 'cache-first',
    });

    return {
      ok: !!books,
      data: books as Book[],
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};

export const getTagPrediction = async (
  variables: GetTagsPredictiveQueryVariables,
): Promise<Response<TagsResult[]>> => {
  try {
    const { query } = initializeApollo();

    const {
      data: { tags },
    } = await query<GetTagsPredictiveQuery>({
      query: GetTagsPredictiveDocument,
      variables,
      fetchPolicy: 'cache-first',
    });

    return {
      ok: !!tags,
      data: tags,
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};
