import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

interface ReturnGetType {
  route: string;
}

const useGetType = (routeInput?: string): ReturnGetType => {
  const { asPath } = useRouter();
  const [route, setRoute] = useState<string>(routeInput);

  const getType = (): void => {
    if (!asPath) return;

    const [, splitRoute, secondRoute, searchRoute] = asPath.split('/');

    if (splitRoute === 'areas') {
      setRoute('areas');
      return;
    }

    if (['revistas', 'resultados-revistas'].includes(splitRoute)) {
      setRoute('revistas');
      return;
    }

    if (splitRoute === 'tags') {
      setRoute('tags');
      return;
    }

    if (
      ['libros'].includes(splitRoute) ||
      ['libro', 'libros'].includes(secondRoute) ||
      ['libros'].includes(searchRoute)
    ) {
      setRoute('book');
      return;
    }

    if (
      ['ebooks'].includes(splitRoute) ||
      ['ebook', 'ebooks'].includes(secondRoute) ||
      ['ebooks'].includes(searchRoute)
    ) {
      setRoute('ebook');
      return;
    }

    if (
      ['modelo', 'modelos', 'modelos-anatomicos-educativos'].includes(splitRoute) ||
      ['modelo', 'modelos', 'modelos-anatomicos-educativos'].includes(secondRoute) ||
      ['modelos', 'modelos-anatomicos-educativos'].includes(searchRoute)
    ) {
      setRoute('model');
      return;
    }

    if (
      ['material', 'material-y-suministros-medicos'].includes(splitRoute) ||
      ['material', 'material-y-suministros-medicos'].includes(secondRoute) ||
      ['material', 'material-y-suministros-medicos'].includes(searchRoute)
    ) {
      setRoute('material');
      return;
    }

    if (
      ['poster', 'laminas'].includes(splitRoute) ||
      ['poster', 'laminas'].includes(secondRoute) ||
      ['poster', 'laminas'].includes(searchRoute)
    ) {
      setRoute('poster');
    }
  };

  useEffect(() => {
    getType();
  }, [asPath]);

  return { route };
};

export default useGetType;
