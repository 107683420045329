import React from 'react';
import { sortBy } from 'lodash';

import urlPredictionTag from './utils/predictionTag.util';
import CacheAxonConstant from '../../../utils/axonConstant';
import { TagsResult } from '../../../graphqlTypes';
import Tag from '../Tag';

interface PredictionTagProps {
  predictionsTags: TagsResult[];
  type: string;
  goToTags: (url: string) => void;
  loadingTags: boolean;
  areaOfInterestName: string;
}

const PredictionTag: React.FC<PredictionTagProps> = ({
  predictionsTags,
  type,
  goToTags,
  loadingTags,
  areaOfInterestName,
}) => {
  const categories = sortBy(CacheAxonConstant.categories, 'order');

  return (
    <>
      {predictionsTags.map((prediction) => {
        const categ = categories.find((e) => e.value === prediction.category && !e.hidden);
        const { tagEs, _id, name, category } = prediction;

        if (!categ) {
          const url = `/resultados/tags/${tagEs}`;

          return (
            <Tag
              avoidBlink
              preventDefault
              onClick={(): void => {
                goToTags(url);
              }}
              url={url}
              color="black"
              value={tagEs}
              key={`${_id}i`}
            />
          );
        }

        if (prediction.name === 'AGOTADO') {
          return null;
        }

        return (
          <Tag
            avoidBlink
            preventDefault
            onClick={(): void => {
              goToTags(urlPredictionTag(name, category, type));
            }}
            url={urlPredictionTag(name, category, type)}
            color={categ?.color || 'grey'}
            value={name}
            key={`${_id}i`}
          />
        );
      })}

      {loadingTags ? (
        <p key="predictions_noResults_tag_loading" className="noResults">
          {/* Cargando tags ... */}
        </p>
      ) : null}

      {!predictionsTags?.length ? (
        <p key="predictions_noResults_tag" className="noResults">
          {areaOfInterestName
            ? `No hay tags con esta búsqueda para ${areaOfInterestName}`
            : ''}
        </p>
      ) : null}
    </>
  );
};

export default PredictionTag;
