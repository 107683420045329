import { useEffect, useState } from 'react';

export default function useDebounce<T>(
  value: T,
  delay = 1000,
): { debouncedValue: T; firstTimeDebounce: boolean } {
  const [firstTimeDebounce, setFirstTime] = useState(true);
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    if (firstTimeDebounce) {
      setFirstTime(false);
      return undefined;
    }
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return (): void => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return { debouncedValue, firstTimeDebounce };
}
