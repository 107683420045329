import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { isEqual } from 'lodash';

import ProductListed from '../ProductListed/ProductListed';
import { Book } from '../../../graphqlTypes';
import Button from '../forms/Button';
import { getDataFromStorage, NameStorage } from '../../../utils/services/localStorage.service';
import LoadingOval from "../LoadingOval";

interface PredictionProduct {
  predictionProducts: Book[];
  toggleSearch: () => void;
  _search: (value: string, forceAll: boolean) => void;
  isSearchingResults: boolean;
  loading: boolean;
  areaOfInterestName: string;
  searchAll: () => void;
}

const PredictionProduct: React.FC<PredictionProduct> = ({
  predictionProducts,
  toggleSearch,
  _search,
  isSearchingResults,
  loading,
  areaOfInterestName,
  searchAll,
}) => {
  const { asPath } = useRouter();
  const [productSelected, setProductSelected] = useState<Book>(null);

  const currentProductClicked = (product: Book): void => {
    setProductSelected(product);
  };

  const setAccessFromExpandedSearch = (_, event): void => {
    if (event?.nativeEvent?.ctrlKey || event?.nativeEvent?.metaKey) {
      event.stopPropagation();
    }
  };

  return (
    <>
      {predictionProducts?.map((productPrediction) => {
        const { titleFriendly, ean } = productPrediction;

        return (
          <ProductListed
            toggleSearch={toggleSearch}
            onClick={setAccessFromExpandedSearch}
            search={_search}
            titleFriendly={titleFriendly}
            key={`${ean}`}
            product={productPrediction}
            onClickToSearch={currentProductClicked}
            isLoading={isEqual(productPrediction, productSelected)}
          />
        );
      })}

      {isSearchingResults && loading ? (
        <div className='predictiveWrapper'>
          <LoadingOval 
            ariaLabel="loading-indicator-ProductListining"
            height={50}
            width={40}
            strokeWidth={3.5}
            color="red"
            secondaryColor="white"
          />
        </div>
      ) : null}
      
      {!predictionProducts.length && !loading && !isSearchingResults ? (
        <div key="predictions_noResults_pred">
          <p className="noResults">
            {areaOfInterestName
              ? `No hay resultados con esta búsqueda para ${areaOfInterestName}`
              : 'No hay resultados con esta búsqueda'}
          </p>
          {
            // botón de 'Buscar en el resto de áreas', solo visible cuando hay un area de interés y en la sección de libros
            (asPath.indexOf('/libros') === 0 || asPath.indexOf('/ebooks') === 0) &&
              getDataFromStorage(NameStorage.areaOfInterestName) && (
                <Button
                  iconClass="arrow-right"
                  iconRight
                  className="rounded bordered cta margin-top-30"
                  onClick={searchAll}
                >
                  Buscar en el resto de áreas
                </Button>
              )
          }
        </div>
      ) : null}
    </>
  );
};

export default PredictionProduct;
