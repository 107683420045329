import { tagAvailability, tabByValue, tabByObjectAndField } from './utils';

interface AxonCategory {
  value: string;
  name: string;
  order: number;
  ref: string;
  color: string;
  options?;
  hidden?: boolean;
  type?: {
    material?: {
      name?: string;
      show?: boolean;
    };
    model?: {
      name?: string;
      show?: boolean;
    };
  };
}

interface CacheAxon {
  areas?: unknown[];
  temas?: unknown[];
  categories?: AxonCategory[];
}

const categories: AxonCategory[] = [
  {
    value: 'tags',
    order: 1,
    color: 'black',
    ref: '',
    options: {},
    name: 'Tema',
  },
  {
    value: 'authors',
    order: 2,
    color: 'teal',
    ref: 'book.authors',
    options: { fn: tabByObjectAndField, prop: 'name' },
    name: 'Autor',
    type: {
      material: {
        name: 'Referencia',
      },
      model: {
        name: 'Referencia',
      },
    },
  },
  {
    value: 'availability',
    order: 3,
    color: 'green',
    ref: '',
    options: { fn: tagAvailability },
    name: 'Disponibilidad',
  },
  {
    value: 'brands',
    order: 4,
    color: 'orange',
    ref: 'brands',
    options: { fn: tabByObjectAndField, prop: 'name' },
    name: 'Editorial',
    type: {
      material: {
        name: 'Fabricante',
      },
      model: {
        name: 'Fabricante',
      },
    },
  },
  {
    value: 'warranty',
    order: 5,
    color: 'red',
    ref: '',
    options: {},
    name: 'Garantía',
  },
  {
    value: 'languages',
    order: 0,
    color: 'blue',
    ref: 'book.lang',
    options: { fn: tabByValue },
    name: 'Idioma',
    type: {
      material: {
        show: false,
      },
      model: {
        show: false,
      },
    },
  },
];

const CacheAxonConstant: CacheAxon = {
  categories,
};

export default CacheAxonConstant;
